.button-parent {
  margin: 41px 0 80px 8px;
  cursor: pointer;
  width: 192px;
  display: block;
}

.button-item {
  border: 2px solid #141416;
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.btn-2 {
  display: block;
  cursor: pointer;
  padding: 8px 10px;
  position: relative;
  z-index: 2;
  transition: all 300ms ease;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  border-radius: 0 4px 4px 0;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #141416;
}

.btn-2:before {
  position: absolute;
  content: "";
  width: 8px;
  height: calc(100% + 4px);
  background: #056ae7;
  top: -2px;
  left: -7px;
  z-index: -1;
  transition: all 300ms ease;
  border-radius: 4px 0 0 4px;
}

.btn-2:hover {
  color: white;
  box-shadow: none;
}

.btn-2:hover:before {
  position: absolute;
  content: "";
  width: calc(100% + 9px);
  background: #056ae7;
  right: 0;
  z-index: -1;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .button-parent {
    margin: 21px 0 40px 8px;
  }
}
@media (max-width: 480px) {
  .button-parent {
    margin: 15px 0 30px 8px;
  }
}
